.zoom-animation-1 {
  animation: zoom-in-zoom-out 0.3s ease;
}

.zoom-animation-2 {
  animation: zoom-in-zoom-out-2 0.3s ease;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out-2 {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
